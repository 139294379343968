import { Box, Layout, SideBarToogle, DropDown, Grid, Text, Avatar } from '@flexisaf/flexibull2';
import { Fragment } from 'react';
import Logo from 'assets/safsims.svg';
import { Theme } from 'config/theme';
import { Outlet } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { SideBarMainMenu, ProfileMenu } from './menu';

export const ListItem = styled.li`
  margin: 0 0 15px -20px;
  list-style-type: none;
  position: relative;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  &::before {
    ${(props) =>
      props.status === 'pending' &&
      css`
        content: '';
        /* position: absolute; */
        display: inline-block;
        margin: -5px 10px -5px 0;
        width: ${(props) => (props.indicatorSize ? props.indicatorSize : '15px')};
        height: ${(props) => (props.indicatorSize ? props.indicatorSize : '15px')};
        border: 2px solid ${Theme.PrimaryBorderColor};
        border-radius: 3px;
      `}

    ${(props) =>
      props.status === 'done' &&
      css`
        /* content: '✓'; */
        content: '✓';
        /* position: absolute; */
        display: inline-block;
        margin: -5px 10px -5px 0;
        font-size: 16px;
        color: ${Theme.PrimaryGreen};
      `};

    ${(props) =>
      props.status === 'current' &&
      css`
        content: '◉';
        /* position: absolute; */
        border-radius: 100%;
        display: inline-block;
        margin: -5px 10px -5px 0;
        font-size: 16px;
        color: ${Theme.PrimaryColor};
        /* width: ${(props) => (props.indicatorSize ? props.indicatorSize : '15px')};
                  height: ${(props) => (props.indicatorSize ? props.indicatorSize : '15px')};
                  border: 1px solid ${Theme.PrimaryColor}; */
      `}
  }
`;

const TopNavBar = () => {
  return (
    <Fragment>
      <Grid default="max-content">
        <Box margin="0 20px 0 0" align="right">
          <Box display="flex" style={{ alignItems: 'center', cursor: 'pointer' }}>
            <DropDown
              style={{ zIndex: 10, textAlign: 'left' }}
              label={
                <Box pad="0 10px" display="flex" style={{ alignItems: 'center' }}>
                  <Box margin="0 -10px 0 0">
                    <Avatar
                      src={''}
                      name={'User'}
                      margin="0 10px"
                      size="30px"
                      status="online"
                      round
                    />
                  </Box>
                </Box>
              }
              menuAlign="bottom right"
              trigger="click"
              width="150px"
            >
              {ProfileMenu?.map((item, index) => {
                return (
                  <li key={item.key}>
                    <Text block color={item.color} style={{ padding: '3px 0' }}>
                      <i className={item.icon} style={{ marginRight: '10px' }} />
                      {item.label}
                    </Text>
                  </li>
                );
              })}
            </DropDown>
          </Box>
        </Box>
      </Grid>
    </Fragment>
  );
};

function Dashboard() {
  return (
    <>
      <Layout
        theme={Theme}
        withSideNav
        withTopNav
        sideNavOpenWidth="240px"
        sideNavCloseWidth="80px"
        collapseIcon="saf-arrow-circle-left"
        expandIcon="saf-arrow-circle-right"
        topNavProps={{
          background: 'transparent',
          elevate: 'none',
          scrollable: true,
          borderline: Theme.PrimaryBorderColor,
          style: {
            overflow: 'visible',
          },
        }}
        sideNavProps={{
          float: window.innerWidth <= 480 ? true : false,
          background: '#FFF',
          brandHeight: '30px',
          borderline: Theme.PrimaryBorderColor,
          brand: (
            <SideBarToogle>
              <Box margin="0 0 0 4px">
                <img src={Logo} alt="safsims" width="220px" />
              </Box>
            </SideBarToogle>
          ),
          menuList: SideBarMainMenu,
        }}
        topNavContent={<TopNavBar />}
      >
        <Box pad="100px 0 0 0">
          <Outlet />
        </Box>
      </Layout>
    </>
  );
}

export default Dashboard;
