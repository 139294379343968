import { Box, Button, Input, Spacer } from '@flexisaf/flexibull2';
import styled from 'styled-components';
import logo from '../assets/img/logo.png';

const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #f3f4f6;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 375px) {
    height: 100vh;
  }
`;

const LoginWrap = styled.div`
  margin-bottom: 1em;
  margin-right: auto;
  margin-left: auto;
  width: 35%;
  padding: 2em 2em;

  /* Custom, iPhone Retina */
  @media only screen and (min-width: 320px) {
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (min-width: 768px) {
    width: 60%;
  }

  /* Small Devices, Tablets */
  @media only screen and (min-width: 1280px) {
    width: 35%;
  }

  & h1 {
    color: #023857;
    font-size: 4em;
    line-height: 1px;

    @media only screen and (min-width: 320px) {
      font-size: 2.4em;
    }
  }
`;

const LoginForm = styled.div`
  background: white !important;
  padding: 5em 2em;
  width: 100%;
  box-shadow: 0px 30px 50px 0px rgb(0 0 0 / 20%);
`;

const Foot = styled.div`
  background-color: #f3f4f6;
  margin: 1em -2em -5em -2em;
  padding: 1em 0em;
`;

const Login = () => {
  return (
    <Wrapper>
      <div>
        <Box pad="0px 20px 0px 20px" align="center">
          <LoginWrap>
            <img src={logo} height="50px" alt="neco logo" />
            <Spacer space={10} />
            <h1>SAFSIMS DASHBOARD </h1>
            <Spacer space={10} />
            <LoginForm>
              <Box pad="20px 0" align="center">
                <Input type="email" forminput label="Email" required width="90%" />
                <Spacer space={40} />
                <Input type="password" forminput label="Password" required width="90%" />
                <Spacer space={20} />
                <Button style={{ width: '90%' }}>LOGIN</Button>
                <Spacer space={10} />
              </Box>
              <Foot>
                <p>
                  Forgot your password? <a href="/forgotpassword">click here</a>{' '}
                </p>
              </Foot>
            </LoginForm>
          </LoginWrap>
        </Box>
      </div>
    </Wrapper>
  );
};

export default Login;
