import { Fragment, Suspense } from 'react';
import './App.css';

import { Layout } from '@flexisaf/flexibull2';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Lazy } from 'utils/ComponentLoader';
import { Theme } from 'config/theme';
import Login from 'pages/Login';
import Dashboard from 'pages/Dashboard';
import { StyledLayout } from 'style';

// O P E N    R O U T E S
// const LoginPage = Loadable(() => import('pages/login/index'));

// G U A R D E D    R O U T E S
const NotificationConfig = Lazy(() => import('pages/notification-configuration/index'));
const UserManagement = Lazy(() => import('pages/user-management'));
const PaymentSetting = Lazy(() => import('pages/payment-settings'));
const KycConfiguration = Lazy(() => import('pages/kyc-configuration'));

function App() {
  return (
    <>
      <Fragment>
        <StyledLayout>
          <Layout theme={Theme}>
            <Router>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/" element={<Dashboard />}>
                  <Route
                    path="/notification-configuration"
                    element={
                      <Suspense>
                        <NotificationConfig />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/user-management"
                    element={
                      <Suspense>
                        <UserManagement />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/payment-settings"
                    element={
                      <Suspense>
                        <PaymentSetting />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/kyc-configuration"
                    element={
                      <Suspense>
                        <KycConfiguration />
                      </Suspense>
                    }
                  />
                </Route>
              </Routes>
            </Router>
          </Layout>
        </StyledLayout>
      </Fragment>
    </>
  );
}

export default App;
