import { Theme } from 'config/theme';

export const SideBarMainMenu = [
  {
    name: 'notification',
    icon: 'saf-notification-status',
    label: 'Notification',
    navlink: '/notification-configuration',
  },
  {
    name: 'user-management',
    icon: 'saf-people',
    label: 'User Management',
    navlink: '/user-management',
  },
  {
    name: 'payment-settings',
    icon: 'saf-receipt-item',
    label: 'Fees Configuration',
    navlink: '/payment-settings',
  },
  {
    name: 'KYC setting',
    icon: 'saf-clipboard-tick',
    label: 'KYC Configuration',
    navlink: '/kyc-configuration',
  },
];

export const ProfileMenu = [
  {
    key: 'profile',
    icon: 'saf-frame-1',
    label: 'Profile',
    color: '',
  },
  {
    key: 'log_out',
    icon: 'saf-logout',
    label: 'Log Out',
    color: Theme.PrimaryRed,
  },
];
